const en = {
    translation: {
        Title: 'Tournament of Towns',
        TournamentTitle: '{{number, ordinal}} Tournament of Towns',
        SeasonFullTitle:
            '{{number, ordinal}} Tournament of Towns — $t(common:season, { "context": "{{ season }}" })',
        SeasonTitle: '$t(common:Season, { "context": "{{ season }}" }) round',
        TierTitle: '$t(common:Tier, { "context": "{{ tier }}" }) version',
        LevelTitle: '$t(common:Level, { "context": "{{ level }}" })',
        LevelFullTitle:
            '{{number, ordinal}} Tournament of Towns, $t(common:season, { "context": "{{ season }}" }) $t(common:level, { "context": "{{ level }}" }) round',
        ProblemWithNumber: 'Problem {{problemNumber}}',
        TierAndProblemWithNumber:
            '$t(common:Tier, { "context": "{{ tier }}" }) version, problem {{problemNumber}}',
        Loading: 'Loading...',
        UnknownErrorMessage: 'Unexpected error. Please try again.',
        Save: 'Save',
        Next: 'Next',
        Version: 'Version',
        marks: 'marks',
        points: 'points',
        result: 'result',
        Award_DIPLOMA: 'Diploma',
        Award_PRIZE: 'Prize',
        award_DIPLOMA: 'diploma',
        award_PRIZE: 'prize',

        PaperFlag_centralExamination: 'Send to central marking',
        PaperFlag_pagesProblem: 'Problem with files',
        PaperFlag_suspicious: 'Plagiarism suspicion',
        PaperFlag_annulled: 'Annulled',

        CheckEmailLink: {
            CheckEmailAt: 'Check email at {{provider}}',
        },
        TopNavigation: {
            Title: 'Tournament of Towns',
            SignIn: 'Sign in',
            SignOut: 'Sign out',
        },
        Footer: {
            TurgorLink: 'turgor.ru',
            PrivacyPolicy: 'Privacy policy',
            Faq: 'FAQ',
        },
        SignInPage: {
            Header: 'Tournament of Towns — sign in',
            NotRegistered: 'Not signed up yet?',
            CreateAccount: 'Create account',
            EmailLabel: 'Email',
            PasswordLabel: 'Password',
            SignInButton: 'Sign in',
            InvalidUserOrPasswordErrorMessage: 'Invalid email or password.',
            SignInSuccessMessage: 'Successfully signed in',
            EmailRequired: 'Enter your email',
            EmailPatternFailed: 'Email must contain @ symbol',
            PasswordRequired: 'Enter your password',
            ForgotYourPassword: 'Forgot your password?',
            RestorePassword: 'Reset',
        },
        SignUpPage: {
            Header: 'Tournament of Towns — sign up',
            HeaderOrganizers: 'Tournament of Towns — organizer sign up',
            AlreadyRegistered: 'Already signed up?',
            SignIn: 'Sign in',
            EmailLabel: 'Email',
            PasswordLabel: 'Password',
            PasswordRequirements: 'At least 8 symbols',
            Consent: 'I agree to my data being processed.',
            PrivacyPolicy: 'Privacy policy',
            SignUpButton: 'Sign up',
            AlreadySignedUpErrorMessage:
                'This email is already used by another user',
            SignUpSuccessMessage: 'Successfully signed up',
            CompleteSignUpProcess: `To complete the registration, please verify your email.<br/> 
                To do this, please follow the link sent to
                <strong>{{email}}</strong>.`,
            EmailRequired: 'Enter your email',
            EmailPatternFailed: 'Email must contain @ symbol',
            PasswordRequired: 'Enter your password',
            PasswordMinLength: 'Password must be at least 8 symbols long',
            ConsentRequired: 'Please agree to the privacy policy',
        },
        ResetPasswordPage: {
            RestorePassword: 'Reset your password',
            GetRestoreLink: 'Get the password reset link',
            EmailLabel: 'Email',
            EmailRequired: 'Enter your email',
            EmailPatternFailed: 'Email must contain @ symbol',
            PasswordLabel: 'New password',
            PasswordRequired: 'Enter your password',
            PasswordMinLength: 'Password must be at least 8 symbols long',
            NotSignedUp: 'User with this email is not found',
            ResetPasswordRequestSuccessMessage: 'Link sent',
            FollowTheLink:
                'To reset your password please follow the link sent to <strong>{{email}}</strong>',
            SetPassword: 'Set new password',
            SetPasswordSuccess: 'New password set successfully',
            PasswordResetTokenExpired: 'Password reset link has expired.',
            RequestNewLink: 'Request new link',
        },
        VerifyEmailPage: {
            Error: 'Error, unable to verify email',
            Success: 'Email {{email}} has been verified',
        },
        ParticipantHomePage: {
            VerifyEmailHeader: 'Please, verify your email',
            PleaseVerifyYourEmail: `To verify your email, please follow the link sent to <strong>{{email}}</strong>.`,
            DidNotReceive:
                'If you didn\'t get the email, please check your "Spam" folder.<br/>Can\'t find the email?',
            ResendLink: 'Resend verification link',
            StillNoLink:
                "If you didn't receive an email within an hour — contact us at ",
            LinkSent: `Link resent to <strong>{{email}}</strong>`,
            monthPrepositional_0: 'January',
            monthPrepositional_1: 'February',
            monthPrepositional_2: 'March',
            monthPrepositional_3: 'April',
            monthPrepositional_4: 'May',
            monthPrepositional_5: 'June',
            monthPrepositional_6: 'July',
            monthPrepositional_7: 'August',
            monthPrepositional_8: 'September',
            monthPrepositional_9: 'October',
            monthPrepositional_10: 'November',
            monthPrepositional_11: 'December',
            RegistrationWillBeOpenSoon:
                'Registration to the $t(common:season, { "context": "{{ season }}" }) round of {{number, ordinal}} Tournament of Towns will be open soon.',
            YourPaperIsSubmitted: 'Your paper is submitted',
            ResultsWillBeAvailableAfterMarking:
                'Results will be available after the marking',
            PastTournaments: 'Past tournaments',
            PaperWasNotScanned:
                'The paper was not digitized and was marked in the original form.',
            Appeal: 'Appeal',
            SubmitAppeal: 'Submit an appeal',
            ViewAppeal: 'View appeal',
            NoInfoForPastTournaments:
                'No info about your participation in the past tournament',
            YouAreAwardedWith:
                'You are awarded with a $t(Award, { "context": "{{ award }}" }) of {{number, ordinal}} Tournament of Towns',
            BestThreeSolutionsAreConsidered:
                'Three problems with best results are considered for the final result.',
        },
        PaperModal: {
            HideComments: 'Hide comments',
            ZoomIn: 'Zoom in',
            ZoomOut: 'Zoom out',
            ContrastPlus: 'Contrast +',
            ContrastMinus: 'Contrast -',
            Problem: 'Problem',
            page: 'page',
            Page: 'Page',
            Close: 'Close',
        },
        QuestionModal: {
            HereYouCanAskYourQuestions:
                'Here you can submit your question to the jury',
            QuestionTopic: 'Topic',
            other: 'other',
            ChooseQuestionTopic: 'Choose a topic',
            YourQuestion: 'Your question',
            EnterYourQuestion: 'Please, enter your question',
            Send: 'Send',
        },
        ExaminationPaperView: {
            ProblemSolutionHasNotBeenUploaded:
                'Participant did not upload a solution for this problem',
            Choose: 'Select',
            Examinations: 'Marks',
            CommentPlaceholder: 'You can leave a comment',
            SaveComment: 'Save comment',
            Local: 'Local jury',
            Central: 'Central jury',
            OpenPage: 'Open page',
            ShowProblem: 'Show problem text',
            DetermineZeros: 'Auto-fill zeros',
        },
        RegisterForTournamentPage: {
            RegisterForFallRound: 'Registration to the fall round',
            RegisterForSpringRound: 'Registration to the spring round',
            EmailLabel: 'Email',
            NameLabel: 'Name',
            SurnameLabel: 'Surname',
            PhoneLabel: 'Phone',
            GradeLabel: 'Grade',
            TownNameLabel: 'Town',
            VenueLabel: 'Choose a venue',
            CustomTownNameLabel: 'Enter other town',
            SchoolLabel: 'School',
            CustomSchoolLabel: 'Enter your school',
            RegisterButtonLabel: 'Register',
            otherTown: 'other',
            otherSchool: 'other',
            NameRequired: 'Please enter your name',
            SurnameRequired: 'Please enter your surname',
            PhoneRequired: 'Please enter your phone',
            PhonePatternFailed: 'You can use digits and +, -, (, )',
            GradeRequired: 'Please enter your grade',
            GradePatternFailed: 'Grade must be from 1 to 12',
            TownNameRequired: 'Please select a town',
            VenueSelectRequired: 'Please select a venue',
            SchoolRequired: 'Please enter your school',
            LevelRequired: 'Choose at least one level',
            RegistrationSuccess: 'Successful registration',
            LevelDate: `$t(common:Level, { "context": "{{ level }}" }) ({{date, Europe/Moscow | MMMM do}})`,
            AttentionVenueHoldsTournament:
                '<strong>Attention!</strong> Selected venue holds the Tournament',
            ChooseAnotherVenue: 'Select another venue',
            YesIUnderstand: 'Yes, I understand',
            NoSpotsLeft:
                'Unfortunately, there are no spots left available at the selected venue',
            WhatLevelsDoYouWantToParticipateIn:
                'What levels do you want to participate in?',
            youCanChooseOneOrBoth: '(choose one or both)',
            levelNotAvailableAtVenue: ' (not available at this venue)',
            noSpotsForLevelLeftAtVenue: ' (no free spots left at this venue)',
        },
        ParticipationInfo: {
            YouAreRegisteredToFallRound:
                'You are registered for the fall round',
            YouAreRegisteredToSpringRound:
                'You are registered for the spring round',
            OrdinaryRoundDate: `O-level will be held on <strong>{{ordinaryDate, Europe/Moscow | MMMM do yyyy}}</strong>, A-level — <strong>{{advancedDate, Europe/Moscow | MMMM do}}</strong>.`,
            AdvancedRoundDate: `A-level will be held on <strong>{{advancedDate, Europe/Moscow | MMMM do yyyy}}</strong>.`,
            AdvancedRoundNoReadyYet: `A-level will be held on <strong>{{advancedDate, Europe/Moscow | MMMM do yyyy}}</strong>. More information will be available on<strong>{{advancedReadyDate, Europe/Moscow | MMMM do}}</strong>.`,
            RegistrationData: 'Your registration data',
            Email: 'Email',
            Name: 'Name',
            Surname: 'Surname',
            Phone: 'Phone',
            Grade: 'Grade',
            GradeNumber: '{{ grade, ordinal }} grade',
            TownName: 'Town',
            School: 'School',
            CardNumber: 'Participation number',
            YouWillBeAbleToParticipateOnlineOrdinary: `You will be able to participate in the Tournament online, on this website. Information about A-level will be available later.`,
            YouWillBeAbleToParticipateOnlineAdvanced: `You will be able to participate in the Tournament online, on this website`,
            OnlineDetails: `You will be able to start solving problems at <strong>{{startDate, HH:mm}}</strong> 
                ({{startDate, Europe/Moscow | HH:mm}} GMT+3).<br/>
                You are required to upload you solutions in 5 hours and 30 minutes after you start, 
                but no later than <strong>{{endDate, HH:mm}}</strong> 
                ({{endDate, Europe/Moscow | HH:mm}} GMT+3).`,
            YouWillBeAbleToParticipateOfflineOrdinary: `You will be able to participate in the Tournament offline. Information about A-level will be available later.`,
            YouWillBeAbleToParticipateOfflineAdvanced: `You will be able to participate in the Tournament offline.`,
            Venue: 'Venue',
            PrintOut: 'Please print out the',
            titlePage: 'title page',
            OrWriteDownYourCard: `Alternatively, write your participation number on your paper: <strong>{{card}}</strong>`,
            YouCanChangeVenue:
                'You can choose another venue until the day of the Tournament.',
            ChangeVenue: 'Change venue',
            ContactEmail: "Organizers' contact email",
            ContactPhone: 'Phone',
            ContactWebsite: 'Website',
            ContactMessengers: 'Messengers',
            ContactMessenger_telegram: 'Telegram',
            ContactMessenger_whatsapp: 'WhatsApp',
            ContactMessenger_viber: 'Viber',
            ContactMessenger_fbMessenger: 'Facebook Messenger',
            juniorTier: 'junior version',
            seniorTier: 'senior version',
            //`Апелляции принимаются в течение двух недель после публикации результатов.
            AppealDeadline:
                //<br/>
                `Your results were published on <strong>{{publishDate, MMMM do yyyy}} at {{publishDate, HH:mm}}</strong>,
                you can appeal until <strong>{{appealDeadline, HH:mm, MMMM do yyyy}}</strong>`,
            AppealDeadlinePassed:
                //<br/>
                `Your results were published on <strong>{{publishDate, MMMM do yyyy}} at {{publishDate, HH:mm}}</strong>,
                appeal form was open before <strong>{{appealDeadline, HH:mm, MMMM do yyyy}}</strong>`,
            YouOnlyWantedOneLevel:
                'Are are only registered to the $t(common:level, { "context": "{{ level }}" }).',
            RegisterToLevel:
                'Register to the $t(common:level, { "context": "{{ level }}" })',
            LevelIsNotAvailableAtSelectedVenue:
                'Attention! Unfortunately, $t(common:level, { "context": "{{ level }}" }) is <strong>not available</strong> at the selected venue, or there are no spots left.',
            SelectAnotherVenueForLevel:
                'Select another venue for the $t(common:level, { "context": "{{ level }}" })',
            NotesForParticipants: `Don't forget to bring a pen and some paper.<br /><br />
                Please, bring a face mask with you.<br /><br />
                Stay safe: if you are feeling unwell, please stay home.`,
        },
        ParticipantPastResults: {
            DigitalAwardNote:
                "You will be able to get the paper diploma from the local organizers in your town, after the Tournament's results are finalized, after the spring round.",
            DigitalAwardNoteFinishedTournament:
                "You can get the paper diploma from the local organizers in your town, after the Tournament's results are finalized.",
            SolutionsAndCriteriaArePublished:
                "See the Tournament's website for <1>solutions and marking criteria</1>",
            SeasonWasHeld:
                '$t(common:Season, { "context": "{{ season }}" }) round of the $t(TournamentTitle, { "number": "{{ number }}" } was held in {{months}} {{year}}',
            ViewPaper: 'View paper',
            ResultsWillBeAvailableAfterMarking:
                'Results will be available here after the marking is complete.',
            YouCanAskLocalOrgsForResults:
                'You can contact the local organizers in your town to get the results of the local marking.',
            DownloadDigitalAwardCopy:
                'Download e-version of the $t(award, { "context": "{{ award }}" })',
        },
        OrganizerHomePage: {
            ApplyToParticipate: 'Apply to hold the Tournament',
            SubmitAnotherApplication: 'Submit another application',
            ApplicationLinkText: `{{townName}} (submitted on {{submittedAt, MMMM do}})`,
            VenueApplicationRejectedMessage:
                'This application is rejected.<br/>We sent the details to <strong>{{email}}</strong>, feel free to contact us if you have any questions.',
            ReturnToMain: 'Back to main page',
            VenueApplicationSuccessfullyEditedMessage:
                'Application successfully edited.<br/>We will send you an email to <strong>{{email}}</strong> when it is processed.',
            VenueApplicationSuccessfullySentMessage:
                'Application successfully submitted.<br/>We will send you an email to <strong>{{email}}</strong> when it is processed.',
            SendApplication: 'Submit application',
            YourPendingApplications: 'Your pending applications',
            YourApplications: 'Your applications',
            Questions: 'Questions',
            Participants: 'Participants',
            Papers: 'Papers',
            HereYouCanAskQuestionsAboutProblems:
                'Here you can ask questions about the Tournament problems, jury members will answer them.',
            DuringRoundYouCanAskQuestions:
                'During the Tournament you can ask questions about the Tournament problems, jury members will answer them.',
            QuestionsAreOnlyAvailableDuringRound:
                'Problem questions are only available during the Tournament',
            ChooseVersion: 'Choose a version',
            QuestionTopic: 'Question topic',
            other: 'other',
            ChooseQuestionTopic: 'Please choose a topic',
            YourQuestion: 'Your question',
            EnterYourQuestion: 'Please enter your question',
            Send: 'Send',
            NobodyHasRegisteredYet: 'Nobody has registered yet',
            TitlePage: 'Title page',
        },
        VenueApplicationForm: {
            OrganizersInfo: "Organizers' info",
            Town: 'Town',
            FieldIsRequired: 'This field is required',
            HoldingOrganization: 'Organization that holds the Tournament',
            HoldingOrganizationHint:
                'Short name, e.g. <strong>School 179</strong> or <strong>Moscow State University</strong>',
            HoldingOrganizationDescription:
                'Who holds the Tournament in your town',
            HoldingOrganizationDescriptionHint:
                'Name of the organization, who organizes the holding etc.',
            Email: 'Email',
            EmergencyPhone: 'Phone for urgent communication',
            EmergencyPhoneHint:
                'This phone will only be visible to the central committee',
            PlannedRounds: 'Planned levels',
            OrdinaryDate: 'O-level ({{date, Europe/Moscow | MMMM do}})',
            AdvancedDate: 'A-level ({{date, Europe/Moscow | MMMM do}})',
            ChooseAtLeastOneLevel: 'Choose at lease one level',
            WhoCanParticipate: 'Who can participate',
            ClosedAccess: 'Registration is closed',
            LimitedAccess:
                'Limited access (e.g. only students of the holding school)',
            RegistrationAccess: 'Anybody who have registered beforehand',
            FreeAccess:
                "Anybody (including participants who didn't register beforehand)",
            AccessDetails: 'Details about the access restrictions',
            AccessDetailsHint: 'Describe who can participate',
            ParticipantsNumberLimit: 'Participants number limit',
            ParticipantsNumberLimitHint:
                'If there is a hard limit on the number of participants you can accommodate, specify it',
            Holding: 'Holding',
            InformationForParticipants: 'Information for participants',
            DoubleCheckInfo:
                'Please, check the information below and make sure to update it for the spring round, if necessary.',
            AccessDescriptionForParticipants:
                'Restrictions description for the participants',
            AccessDescriptionForParticipantsHint:
                'Short description for the participants. E.g.: <strong>Only for students of school 179</strong>',
            Address: 'Address',
            StartTime: 'Local start time',
            StartTimeHint: 'Planned start time, HH:MM',
            Notes: 'Notes',
            NotesHint: 'E.g.: Use of masks is mandatory',
            EmailForParticipants: 'Email (for participants)',
            PhoneForParticipants: 'Phone (for participants)',
            Website: 'Website',
            Telegram: 'Telegram',
            Whatsapp: 'WhatsApp',
            Viber: 'Viber',
            FbMessenger: 'Facebook Messenger',
            HowWillThisLookForParticipants:
                'How will this look like for participants',
            CorrectionsPossible: 'Central committee may make small edits',
            Comments: 'Comments',
            CommentsSuggestionsQuestions: 'Comments, suggestions, questions',
        },
        VenueParticipationInfo: {
            StartTime: 'Tournament starts at {{startTime}} (local time).',
            Venue: 'Venue',
            ContactEmail: "Organizers' contact email",
            ContactPhone: 'Phone',
            ContactWebsite: 'Website',
            ContactMessengers: 'Messengers',
            ContactMessenger_telegram: 'Telegram',
            ContactMessenger_whatsapp: 'WhatsApp',
            ContactMessenger_viber: 'Viber',
            ContactMessenger_fbMessenger: 'Facebook Messenger',
        },
        VenuePapersPage: {
            NoPapers: 'No papers',
            LocalMarking: 'Local marking',
            Marking: 'Marking',
        },
    },
};

export default en;
