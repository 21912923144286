const ru = {
    translation: {
        Title: 'Турнир городов',
        TournamentTitle: '{{number}}-й Турнир городов',
        SeasonFullTitle:
            '{{number}}-й Турнир городов — $t(common:season, { "context": "{{ season }}" }) тур',
        SeasonTitle: '$t(common:Season, { "context": "{{ season }}" }) тур',
        TierTitle: '$t(common:Tier, { "context": "{{ tier }}" }) вариант',
        LevelTitle: '$t(common:Level, { "context": "{{ level }}" }) вариант',
        LevelFullTitle:
            '{{number}}-й Турнир городов, $t(common:season, { "context": "{{ season }}" }) $t(common:level, { "context": "{{ level }}" }) тур',
        ProblemWithNumber: 'Задача {{problemNumber}}',
        TierAndProblemWithNumber:
            '$t(common:Tier, { "context": "{{ tier }}" }) вариант, задача {{problemNumber}}',
        Loading: 'Загрузка...',
        UnknownErrorMessage: 'Произошла ошибка. Пожалуйста, попробуйте снова.',
        Save: 'Сохранить',
        Next: 'Далее',
        Version: 'Вариант',
        marks: 'оценки',
        points: 'баллы',
        result: 'результат',
        AwardInstrumental_DIPLOMA: 'Дипломом',
        AwardInstrumental_PRIZE: 'Премией',
        awardGenitive_DIPLOMA: 'диплома',
        awardGenitive_PRIZE: 'премии',
        levelInstrumental_ORDINARY: 'базовом',
        levelInstrumental_ADVANCED: 'сложном',

        timestamp: '{{date, HH:mm}}',
        dateWithMonth: '{{date, d MMMM}}',

        PaperFlag_centralExamination: 'На центральную проверку',
        PaperFlag_pagesProblem: 'Проблема с файлами',
        PaperFlag_suspicious: 'Подозрение на списывание',
        PaperFlag_annulled: 'Аннулирована',

        CheckEmailLink: {
            CheckEmailAt: 'Проверить почту на {{provider}}',
        },
        TopNavigation: {
            Title: 'Турнир городов',
            SignIn: 'Войти',
            SignOut: 'Выйти',
        },
        Footer: {
            TurgorLink: 'turgor.ru',
            PrivacyPolicy: 'Политика конфиденциальности',
            Faq: 'Вопросы и ответы',
        },
        SignInPage: {
            Header: 'Турнир городов — вход',
            NotRegistered: 'Ещё не зарегистрированы?',
            CreateAccount: 'Создать аккаунт',
            EmailLabel: 'Email',
            PasswordLabel: 'Пароль',
            SignInButton: 'Войти',
            InvalidUserOrPasswordErrorMessage: 'Неверный email или пароль.',
            SignInSuccessMessage: 'Успешный вход',
            EmailRequired: 'Введите email',
            EmailPatternFailed: 'Email не содержит @',
            PasswordRequired: 'Введите пароль',
            ForgotYourPassword: 'Забыли пароль?',
            RestorePassword: 'Восстановить',
        },
        SignUpPage: {
            Header: 'Турнир городов — регистрация',
            HeaderOrganizers: 'Турнир городов — регистрация организаторов',
            AlreadyRegistered: 'Уже зарегистрированы?',
            SignIn: 'Войти',
            EmailLabel: 'Email',
            PasswordLabel: 'Пароль',
            PasswordRequirements: 'Восемь или больше знаков',
            Consent: 'Даю согласие на обработку своих персональных данных.',
            PrivacyPolicy: 'Политика конфиденциальности',
            SignUpButton: 'Зарегистрироваться',
            AlreadySignedUpErrorMessage:
                'Пользователь с таким email уже зарегистрирован',
            SignUpSuccessMessage: 'Успешная регистрация',
            CompleteSignUpProcess: `Для завершения регистрации, пожалуйста, 
                подтвердите свой email.<br/> 
                Для этого перейдите по ссылке, отправленной на 
                <strong>{{email}}</strong>.`,
            EmailRequired: 'Введите email',
            EmailPatternFailed: 'Email не содержит @',
            PasswordRequired: 'Введите пароль',
            PasswordMinLength: 'Пароль должен содержать не менее 8 символов',
            ConsentRequired: 'Дайте согласие на обработку персональных данных',
        },
        ResetPasswordPage: {
            RestorePassword: 'Восстановить пароль',
            GetRestoreLink: 'Получить ссылку для восстановления пароля',
            EmailLabel: 'Email',
            EmailRequired: 'Введите email',
            EmailPatternFailed: 'Email не содержит @',
            PasswordLabel: 'Новый пароль',
            PasswordRequired: 'Введите пароль',
            PasswordMinLength: 'Пароль должен содержать не менее 8 символов',
            NotSignedUp: 'Пользователь с таким email не зарегистрирован',
            ResetPasswordRequestSuccessMessage: 'Ссылка отправлена',
            FollowTheLink:
                'Для восстановления пароля перейдите по ссылке, отправленной на <strong>{{email}}</strong>',
            SetPassword: 'Установить пароль',
            SetPasswordSuccess: 'Новый пароль установлен',
            PasswordResetTokenExpired:
                'Истёк срок действия ссылки для восстановления пароля.',
            RequestNewLink: 'Запросить новую',
        },
        VerifyEmailPage: {
            Error: 'Не удалось подтвердить email',
            Success: 'Email {{email}} подтверждён',
        },
        ParticipantHomePage: {
            VerifyEmailHeader: 'Пожалуйста, подтвердите свой email',
            PleaseVerifyYourEmail: `Для подтверждения email перейдите по ссылке,
                отправленной на <strong>{{email}}</strong>.`,
            DidNotReceive:
                'Если вы не получили письмо — пожалуйста, проверьте папку «Спам».<br/>Письмо не нашлось?',
            ResendLink: 'Отправить ссылку ещё раз',
            StillNoLink:
                'Если ссылка не пришла вам на почту в течение часа — напишите с этой почты письмо нам на ',
            LinkSent: `Ссылка повторно отправлена на <strong>{{email}}</strong>`,
            monthPrepositional_0: 'январе',
            monthPrepositional_1: 'феврале',
            monthPrepositional_2: 'марте',
            monthPrepositional_3: 'апреле',
            monthPrepositional_4: 'мае',
            monthPrepositional_5: 'июне',
            monthPrepositional_6: 'июле',
            monthPrepositional_7: 'августе',
            monthPrepositional_8: 'сентябре',
            monthPrepositional_9: 'октябре',
            monthPrepositional_10: 'ноябре',
            monthPrepositional_11: 'декабре',

            RegistrationWillBeOpenSoon:
                'Регистрация на $t(common:season, { "context": "{{ season }}" }) тур {{number}}-го Турнира городов откроется в ближайшее время.',
            YourPaperIsSubmitted: 'Ваша работа принята',
            ResultsWillBeAvailableAfterMarking:
                'Результаты появятся в личном кабинете после проверки работ.',
            PastTournaments: 'Прошедшие турниры',
            PaperWasNotScanned:
                'Работа не была отсканирована и проверялась в бумажном виде.',
            Appeal: 'Апелляция',
            SubmitAppeal: 'Подать апелляцию',
            ViewAppeal: 'Посмотреть апелляцию',
            NoInfoForPastTournaments:
                'Нет информации об участии в прошедших Турнирах',
            YouAreAwardedWith:
                'По итогам {{number}}-го Турнира городов вы награждены $t(AwardInstrumental, { "context": "{{ award }}" }) Турнира городов',
            BestThreeSolutionsAreConsidered:
                'Результат подсчитывается по трём задачам, по которым достигнуты наилучшие результаты.',
        },
        PaperModal: {
            HideComments: 'Скрыть комментарии',
            ZoomIn: 'Увеличить',
            ZoomOut: 'Уменьшить',
            ContrastPlus: 'Контраст +',
            ContrastMinus: 'Контраст -',
            Problem: 'Задача',
            page: 'страница',
            Page: 'Страница',
            Close: 'Закрыть',
        },
        QuestionModal: {
            HereYouCanAskYourQuestions:
                'Здесь вы можете задать свои вопросы членам жюри',
            QuestionTopic: 'Тема вопроса',
            other: 'другое',
            ChooseQuestionTopic: 'Выберите тему вопроса',
            YourQuestion: 'Ваш вопрос',
            EnterYourQuestion: 'Введите ваш вопрос',
            Send: 'Отправить',
        },
        ExaminationPaperView: {
            ProblemSolutionHasNotBeenUploaded:
                'Участник не загрузил решение этой задачи',
            Choose: 'Выбрать',
            Examinations: 'Проверки',
            CommentPlaceholder:
                'Здесь вы можете оставить общий комментарий к работе',
            SaveComment: 'Обновить комментарий',
            Local: 'Местная',
            Central: 'Центральная',
            OpenPage: 'Открыть страницу',
            ShowProblem: 'Показать условие',
            DetermineZeros: 'Определить нули',
        },
        RegisterForTournamentPage: {
            RegisterForFallRound: 'Регистрация на осенний тур',
            RegisterForSpringRound: 'Регистрация на весенний тур',
            EmailLabel: 'Email',
            NameLabel: 'Имя',
            SurnameLabel: 'Фамилия',
            PhoneLabel: 'Телефон',
            GradeLabel: 'Класс',
            TownNameLabel: 'Город',
            VenueLabel: 'Выберите удобную для вас точку проведения Турнира',
            CustomTownNameLabel: 'Укажите свой город',
            SchoolLabel: 'Школа',
            CustomSchoolLabel: 'Укажите свою школу',
            RegisterButtonLabel: 'Зарегистрироваться',
            otherTown: 'другой',
            otherSchool: 'другая',
            NameRequired: 'Укажите своё имя',
            SurnameRequired: 'Укажите свою фамилию',
            PhoneRequired: 'Укажите свой телефон',
            PhonePatternFailed: 'Используйте цифры и знаки +, -, (, )',
            GradeRequired: 'Укажите класс, в котором вы учитесь',
            GradePatternFailed: 'Укажите класс от 1 до 12',
            TownNameRequired: 'Укажите город',
            VenueSelectRequired: 'Выберите точку проведения',
            SchoolRequired: 'Укажите свою школу',
            LevelRequired: 'Выберите хотя бы один из вариантов',
            RegistrationSuccess: 'Успешная регистрация',
            LevelDate: `$t(common:Level, { "context": "{{ level }}" }) ({{date, Europe/Moscow | d MMMM}})`,
            AttentionVenueHoldsTournament:
                '<strong>Внимание!</strong> В выбранной точке Турнир проводится',
            ChooseAnotherVenue: 'Выбрать другую точку',
            YesIUnderstand: 'Да, я учусь здесь',
            NoSpotsLeft:
                'К сожалению, в выбранной точке проведения закончились места',
            WhatLevelsDoYouWantToParticipateIn:
                'В каких вариантах вы хотите участвовать?',
            youCanChooseOneOrBoth: '(можно выбрать один или оба)',
            levelNotAvailableAtVenue: ' (не проводится в выбранной точке)',
            noSpotsForLevelLeftAtVenue: ' (не осталось мест в выбранной точке)',
        },
        ParticipationInfo: {
            YouAreRegisteredToFallRound: 'Вы зарегистрированы на осенний тур',
            YouAreRegisteredToSpringRound:
                'Вы зарегистрированы на весенний тур',
            OrdinaryRoundDate: `Базовый тур пройдёт <strong>{{ordinaryDate, Europe/Moscow | d MMMM yyyy}} года</strong>, сложный тур — <strong>{{advancedDate, Europe/Moscow | d MMMM}}</strong>.`,
            AdvancedRoundDate: `Сложный тур пройдёт <strong>{{advancedDate, Europe/Moscow | d MMMM yyyy}} года</strong>.`,
            AdvancedRoundNoReadyYet: `Сложный тур пройдёт <strong>{{advancedDate, Europe/Moscow | d MMMM yyyy}} года</strong>. Информация о порядке проведения появится ориентировочно <strong>{{advancedReadyDate, Europe/Moscow | d MMMM}}</strong>.`,
            RegistrationData: 'Данные вашей анкеты',
            Email: 'Email',
            Name: 'Имя',
            Surname: 'Фамилия',
            Phone: 'Телефон',
            Grade: 'Класс',
            GradeNumber: '{{ grade, ordinal }} класс',
            TownName: 'Город',
            School: 'Школа',
            CardNumber: 'Номер анкеты',
            YouWillBeAbleToParticipateOnlineOrdinary: `Вы сможете участвовать в базовом туре Турнира онлайн, на этом сайте. Информация о сложном туре появится в личном кабинете позже.`,
            YouWillBeAbleToParticipateOnlineAdvanced: `Вы сможете участвовать в сложном туре Турнира онлайн, на этом сайте.`,
            OnlineDetails: `Приступить к решению задач можно будет начиная с <strong>{{startDate, HH:mm}}</strong> 
                ({{startDate, Europe/Moscow | HH:mm}} МСК).<br/>
                Загрузить свою работу нужно будет в течение 5 часов 30 минут после начала, 
                но не позже <strong>{{endDate, HH:mm}}</strong> 
                ({{endDate, Europe/Moscow | HH:mm}} МСК).`,
            YouWillBeAbleToParticipateOfflineOrdinary: `Вы сможете участвовать в базовом туре Турнира в традиционном очном формате. Информация о сложном туре появится в личном кабинете позже.`,
            YouWillBeAbleToParticipateOfflineAdvanced: `Вы сможете участвовать в сложном туре Турнира в традиционном очном формате.`,
            Venue: 'Место проведения',
            PrintOut:
                'Распечатайте, возьмите с собой на Турнир и сдайте вместе с работой',
            titlePage: 'титульный лист',
            OrWriteDownYourCard: `Если вы не можете распечатать титульный лист, 
                укажите на своей работе номер вашей анкеты: <strong>{{card}}</strong>`,
            YouCanChangeVenue:
                'До дня Турнира вы можете выбрать другую точку проведения.',
            ChangeVenue: 'Поменять',
            ContactEmail: 'Email для связи с организаторами',
            ContactPhone: 'Телефон',
            ContactWebsite: 'Веб-сайт',
            ContactMessengers: 'Мессенджеры',
            ContactMessenger_telegram: 'Telegram',
            ContactMessenger_whatsapp: 'WhatsApp',
            ContactMessenger_viber: 'Viber',
            ContactMessenger_fbMessenger: 'Facebook Messenger',
            juniorTier: 'младший вариант',
            seniorTier: 'старший вариант',
            //`Апелляции принимаются в течение двух недель после публикации результатов.
            AppealDeadline:
                //<br/>
                `Результаты проверки вашей работы опубликованы <strong>{{publishDate, d MMMM yyyy}} года в {{publishDate, HH:mm}}</strong>,
                апелляции по ней принимаются до <strong>{{appealDeadline, HH:mm, d MMMM yyyy}} года</strong>`,
            AppealDeadlinePassed:
                //<br/>
                `Результаты проверки вашей работы опубликованы <strong>{{publishDate, d MMMM yyyy}} года в {{publishDate, HH:mm}}</strong>,
                апелляции по ней принимались до <strong>{{appealDeadline, HH:mm, d MMMM yyyy}} года</strong>`,
            YouOnlyWantedOneLevel:
                'Вы указали, что хотите участвовать только в $t(levelInstrumental, { "context": "{{ level }}" }) туре.',
            RegisterToLevel:
                'Зарегистрироваться на $t(common:level, { "context": "{{ level }}" }) тур',
            LevelIsNotAvailableAtSelectedVenue:
                'Внимание! К сожалению, $t(common:level, { "context": "{{ level }}" }) тур <strong>не проводится</strong> в выбранной точке, или закончились места.',
            SelectAnotherVenueForLevel:
                'Выбрать другую точку для участия в $t(levelInstrumental, { "context": "{{ level }}" }) туре',
            NotesForParticipants: `Не забудьте взять с собой ручку и чистую бумагу (тетрадь или листы А4), на которой вы будете писать.<br /><br />
                Пожалуйста, возьмите с собой маску.<br />
                Участники с повышенной температурой или симптомами ОРВИ не будут допущены к участию в Турнире.<br /><br />
                Просим вас не подвергать себя и окружающих опасности: если у вас есть симптомы ОРВИ, оставайтесь дома.`,
        },
        ParticipantPastResults: {
            DigitalAwardNote:
                'Оригинал диплома можно будет получить у местных организаторов после подведения окончательных итогов Турнира.<br />Они подводятся после весеннего тура по итогам учебного года.',
            DigitalAwardNoteFinishedTournament:
                'Оригинал диплома можно получить у местных организаторов после подведения окончательных итогов Турнира.',
            SolutionsAndCriteriaArePublished:
                'На сайте Турнира опубликованы <1>решения задач и критерии проверки работ</1>',
            SeasonWasHeld:
                '$t(common:Season, { "context": "{{ season }}" }) тур {{number}}-го Турнира городов состоялся в {{months}} {{year}} года',
            ViewPaper: 'Посмотреть работу',
            ResultsWillBeAvailableAfterMarking:
                'Результаты будут доступны в личном кабинете после проверки работ.',
            YouCanAskLocalOrgsForResults:
                'Вы можете обратиться к организаторам Турнира в вашем городе, чтобы узнать результаты местной проверки.',
            DownloadDigitalAwardCopy:
                'Скачать электронную копию $t(awardGenitive, { "context": "{{ award }}" })',
        },
        AdminHomePage: {
            Venues: 'Места проведения',
            Participants: 'Участники',
            VenueApplicationSubmittedAt: `{{submittedAt, d MMMM в HH:mm}}`,
            DefaultVenueApplicationRejectionMessage:
                'Здравствуйте!\n\nК сожалению, ваша заявка на проведение Турнира городов от {{submittedAt, d MMMM}} ({{ townName }}) отклонена.',
        },
        OrganizerHomePage: {
            ApplyToParticipate: 'Подать заявку на участие города в Турнире',
            SubmitAnotherApplication: 'Подать ещё одну заявку',
            ApplicationLinkText: `{{townName}} (подана {{submittedAt, d MMMM}})`,
            VenueApplicationRejectedMessage:
                'Данная заявка отклонена.<br/>Мы отправили письмо с подробностями на <strong>{{email}}</strong>, если у вас остались вопросы — напишите нам снова.',
            ReturnToMain: 'Вернуться на главную',
            VenueApplicationSuccessfullyEditedMessage:
                'Заявка на проведение Турнира успешно отредактирована.<br/>Мы пришлём вам письмо на <strong>{{email}}</strong>, когда рассмотрим её.',
            VenueApplicationSuccessfullySentMessage:
                'Заявка на проведение Турнира успешно отправлена.<br/>Мы пришлём вам письмо на <strong>{{email}}</strong>, когда рассмотрим её.',
            SendApplication: 'Отправить заявку',
            YourPendingApplications: 'Ваши заявки на рассмотрении',
            YourApplications: 'Ваши заявки',
            Questions: 'Вопросы',
            Participants: 'Участники',
            Papers: 'Работы',
            HereYouCanAskQuestionsAboutProblems:
                'Здесь вы можете задать свои вопросы вопросы по условиям, на них ответят члены жюри',
            DuringRoundYouCanAskQuestions:
                'Во время тура здесь можно задать вопросы по условиям, на них ответят члены жюри',
            QuestionsAreOnlyAvailableDuringRound:
                'Вопросы по условиям доступны только во время проведения тура.',
            ChooseVersion: 'Выберите вариант',
            QuestionTopic: 'Тема вопроса',
            other: 'другое',
            ChooseQuestionTopic: 'Выберите тему вопроса',
            YourQuestion: 'Ваш вопрос',
            EnterYourQuestion: 'Введите ваш вопрос',
            Send: 'Отправить',
            NobodyHasRegisteredYet: 'Пока никто не зарегистрировался',
            TitlePage: 'Анкета',
        },
        OnlineRoundUploadZone: {
            DropSomeFiles: 'Перетащите сюда файлы',
            OrClickToSelectFiles: 'Или нажмите, чтобы выбрать файлы',
            ClickToSelectFiles: 'Нажмите, чтобы выбрать файлы',
            UploadUnsuccessful: 'Не удалось загрузить файлы',
            CantProcessFile: 'Не удалось обработать файл',
            InvalidFileType:
                'Неверный формат файла. Выберите файл-изображение: png, jpeg, webp',
            FileTooLarge:
                'Слишком большой файл. Выберите файл размером до 10 Мб',
            FileTooSmall:
                'Слишком маленький файл. Вероятно, это не скан и не фотография. Выберите файл размером от 50 Кб',
            Delete: 'Удалить',
        },
        OnlineTestPaper: {
            HowToUpload: 'Как загружать свою работу',
            TestUpload: 'Тестовая загрузка',
        },
        OnlineRoundPage: {
            Fall: 'Осенний',
            Spring: 'Весенний',
            ordinary: 'базовый',
            advanced: 'сложный',
            junior: 'младший',
            senior: 'старший',
            TimeLeft: 'Осталось',
        },
        VenueApplicationForm: {
            OrganizersInfo: 'Данные об организаторах',
            Town: 'Город',
            FieldIsRequired: 'Поле обязательно для заполнения',
            HoldingOrganization: 'Проводящая организация',
            HoldingOrganizationHint:
                'Короткое название, например <strong>Школа № 179</strong> или <strong>МГТУ «Станкин»</strong>',
            HoldingOrganizationDescription:
                'Кто в вашем городе занимается проведением Турнира',
            HoldingOrganizationDescriptionHint:
                'На базе какой организации проводится Турнир, кто непосредственно руководит и занимается проведением',
            Email: 'Email',
            EmergencyPhone: 'Телефон для экстренной связи',
            EmergencyPhoneHint: 'Этот телефон будет виден только оргкомитету',
            PlannedRounds: 'Планируется проведение',
            OrdinaryDate: 'Базового тура ({{date, Europe/Moscow | d MMMM}})',
            AdvancedDate: 'Сложного тура ({{date, Europe/Moscow | d MMMM}})',
            ChooseAtLeastOneLevel: 'Выберите хотя бы один тур',
            WhoCanParticipate: 'Кто может участвовать',
            ClosedAccess: 'Регистрация закрыта',
            LimitedAccess:
                'Есть ограничения (например, точка принимает только своих учеников)',
            RegistrationAccess:
                'Точка принимает всех желающих при наличии предварительной регистрации',
            FreeAccess:
                'Точка принимает всех желающих (в том числе без предварительной регистрации)',
            AccessDetails: 'Подробнее про ограничения',
            AccessDetailsHint: 'Опишите, кто допускается к участию в Турнире',
            ParticipantsNumberLimit: 'Ограничение по числу участников',
            ParticipantsNumberLimitHint:
                'Если есть строгое ограничение по количеству человек, которых точка может принять, укажите его',
            Holding: 'Очное проведение',
            InformationForParticipants: 'Информация для участников',
            DoubleCheckInfo:
                'Внимательно проверьте заполненную информацию и внесите необходимые изменения для весеннего тура.',
            AccessDescriptionForParticipants:
                'Описание ограничений для участников',
            AccessDescriptionForParticipantsHint:
                'Коротко опишите ограничения для участников. Например, <strong>Только для учеников школы № 179</strong>',
            Address: 'Адрес проведения',
            StartTime: 'Время начала (местное)',
            StartTimeHint: 'Планируемое время начала в формате ЧЧ:ММ',
            Notes: 'Заметки',
            NotesHint: 'Например: обязательно брать с собой сменную обувь',
            EmailForParticipants: 'Email (для участников)',
            PhoneForParticipants: 'Телефон (для участников)',
            Website: 'Веб-сайт',
            Telegram: 'Telegram',
            Whatsapp: 'WhatsApp',
            Viber: 'Viber',
            FbMessenger: 'Facebook Messenger',
            HowWillThisLookForParticipants: 'Как это увидят участники',
            CorrectionsPossible: 'Возможны правки со стороны оргкомитета',
            Comments: 'Комментарии',
            CommentsSuggestionsQuestions:
                'Комментарии, предложения, пожелания, вопросы',
        },
        VenueParticipationInfo: {
            StartTime: 'Начало в {{startTime}} (местное время).',
            Venue: 'Место проведения',
            ContactEmail: 'Email для связи с организаторами',
            ContactPhone: 'Телефон',
            ContactWebsite: 'Веб-сайт',
            ContactMessengers: 'Мессенджеры',
            ContactMessenger_telegram: 'Telegram',
            ContactMessenger_whatsapp: 'WhatsApp',
            ContactMessenger_viber: 'Viber',
            ContactMessenger_fbMessenger: 'Facebook Messenger',
        },
        VenuePapersPage: {
            NoPapers: 'Нет работ',
            LocalMarking: 'Местная проверка',
            Marking: 'Проверка',
        },
    },
};

export default ru;
